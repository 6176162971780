a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

h2 {
  margin-bottom: 8px;
  margin-top: 8px;
}

.MuiPhoneNumber-flagButton {
  width: min-content;
}

.selection-area {
  background: rgba(46, 115, 252, 0.11);
  border: 2px solid rgba(98, 155, 255, 0.81);
  border-radius: 0.1em;
}

.container {
  user-select: none;
}

* {
  /* border: 1px solid rgba(255, 0, 0, 0.1); */
}

div[role='tooltip'] {
  pointer-events: none !important;
}
